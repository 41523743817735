import React from 'react';
import { graphql } from 'gatsby';
import { convertBytesToHumanReadableFileSize, convertStringToId } from '../../functions/common.js';
import Link from '../elements/link';

export const DownloadsListParagraph = ({ node }) => {

  let mediaItems = node.relationships?.field_download_media;

  // Sort downloads alphabetically, case insensitively
  mediaItems = mediaItems ? mediaItems.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA.localeCompare(nameB);
  }) : [];

  const list = mediaItems?.map((item, index) => {
    const fileType = item.hasOwnProperty('customLocalFieldMediaFile')
      ? 'customLocalFieldMediaFile'
      : item.hasOwnProperty('customLocalFieldMediaImage')
      ? 'customLocalFieldMediaImage'
      : null;

    if (fileType === null || item.name === undefined) {
      return <React.Fragment key={`${node.id}-${index}`}></React.Fragment>;
    }

    const size = convertBytesToHumanReadableFileSize(item[fileType].size) || null,
      ext = item[fileType]?.extension.toUpperCase() || null,
      metadata = size && ext ? ` (${ext}, ${size})` : '';

    return item[fileType]?.publicURL ? (
      <li key={`${node.id}-${index}`}>
        <Link
          to={item[fileType]?.publicURL}
          id={`dl-${convertStringToId(item.name)}`}
        >{`${item.name}${metadata}`}</Link>
      </li>
    ) : null;
  });

  if (!list || list.length <= 0) { return <React.Fragment key={node.id}></React.Fragment>}

  return (
    <div
      className="paragraph_downloads_list section--highlight-background pt-large"
      key={node.id}
    >
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0 copy">
            <h2 id="downloads">Download resources</h2>
            <ul>{list}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const fragment = graphql`
  fragment ParagraphDownloadsList on paragraph__downloads_list {
    id
    drupal_id
    internal {
      type
    }
    relationships {
      field_download_media {
        ... on media__document {
          id
          name
          field_site {
            drupal_internal__target_id
          }
          customLocalFieldMediaFile {
            extension
            publicURL
            size
          }
        }
        ... on media__inline_image {
          id
          name
          field_site {
            drupal_internal__target_id
          }
          customLocalFieldMediaImage {
            extension
            publicURL
            size
          }
        }
      }
    }
  }
`;
