// @ts-nocheck
import React from 'react';
import Iframe from './Iframe';
import { getKARdata } from '../fragments/kardata';
import marineZonesActivities from '../../../static/js/marine-zones-permitted-activities.yml';
import { useLocation } from '@reach/router';
import { ListAppsCommand } from '@aws-sdk/client-amplify';

let whichPark = process.env.GATSBY_PARK;

let karString = null;
// @ts-nocheck

/**
 * Embeds a map with specified parameters and points.
 * @param {Object} props - The properties for the map component.
 * @param {string} props.title - The title of the embedded map.
 * @param {number} props.latNum - The latitude of the map center.
 * @param {number} props.lonNum - The longitude of the map center.
 * @param {number} props.zoom - The zoom level of the map.
 * @param {Array} props.points - The array of points on the map (optional).
 * @param {string} props.mapId - Manually set the map id
 * @param {string} props.style - The visual style of the map
 * @returns {React.Element} - The map embedded in an iframe.
 */

function MapEmbed(props) {
  const frameTitle = props.title;
  const latNum = props.latNum; // -34.4
  const lonNum = props.lonNum; // 133
  const zoom = props.zoom; // 2.3 - float, zoom level
  const points = props.points; // Plain text, JSON that contains array of points
  const style = props.style || 'outdoor'; // default to 'outdoor' style
  const location = useLocation();
  const mapId = location.pathname === '/access/' ? 'karmap' : props.mapId || 'map';
  const karData = getKARdata();

  // Load mapbox for normal map embed

  let sourceDoc = `<head></head><body style="margin:0; border:0"><div id="${mapId}" style="width:100%;height:100%"></div>`;

  // Common javascript functions, mapbox code, and out mapbox stylings for the popup boxes.
  sourceDoc += `<script src="/js/jscommon.js"></script>
  <script src="https://cdn.maptiler.com/mapbox-gl-js/v1.13.2/mapbox-gl.js"></script>
  <link href="https://cdn.maptiler.com/mapbox-gl-js/v1.13.2/mapbox-gl.css" rel="stylesheet"/>
  <link href="/js/mapbox-style.css" rel="stylesheet"/>
  <script>map_locations=\`${points}\`;</script>`;

  // Only load AMP data for AMP site
  if (whichPark === 'amp') {
    sourceDoc += `
    <script>let whichPark = "amp";</script>
    <script src="/js/marine-parks-geodata.js"></script>
    <script>marineZonesActivities=${JSON.stringify(marineZonesActivities)};</script>`;
  }

  // I have no idea where react is expecting a key to exist that I don't need or use.
  let keyId = 'map_' + Math.floor(Math.random() * 49152);
  let keyId2 = 'map2_' + Math.floor(Math.random() * 49152);

  // Show a map and table section for the Kakadu access report
  // Do not use/insert an iframe here, we will do that in code --> jscommon::createKARMap()
  if (location.pathname === '/access/') {
    // Just get the nodes object from the data returned by the query
    karString = JSON.stringify(karData.allNodeKarLocation.nodes);
    // Remove unwanted HTML tags, newlines and carriage returns from the rendered string
    karString = karString
      .replace(/(\r\n|\r|\n)/gi, '')
      .replace(/<p[^>]*>/gi, '')
      .replace(/<\/p>/gi, '')
      .replace('\r', '')
      .replace(/<\/a>/gi, '')
      .replace(/<\/a>/gi, '')
      .replace('\n', '');

    let mapPoints = [];
    for (let n in karData.allNodeKarLocation.nodes) {
      let node = karData.allNodeKarLocation.nodes[n];
      let newNode = {};
      // Go through the objects/paragraphs and find a lat/lng for this feature
      let lat = parseFloat(node.field_kar_latitude) || null,
        lng = parseFloat(node.field_kar_longitude) || null;

      // Assign it to a standard variable name
      node.lat = isNaN(lat) ? null : lat;
      node.lng = isNaN(lng) ? null : lng;
      node.comments =
        node.relationships.field_kar_comments && node.relationships.field_kar_comments.name
          ? node.relationships.field_kar_comments.name
          : '';
      node.additionalComments =
        node.field_kar_additional_comments && node.field_kar_additional_comments.value
          ? node.field_kar_additional_comments.value
          : '';
      node.additionalComments = node.additionalComments.replace(/(<([^>]+)>)/gi, '');

      if (!node.lat) {
        console.log(node.title);
      }
      node.statusDisplay =
        node.field_kar_status.charAt(0).toUpperCase() +
        node.field_kar_status.substr(1).toLowerCase();

      // Create a simplified object with only the properties that we need for the map point/popup
      newNode.id = n;
      newNode.title = node.title;
      newNode.lat = node.lat;
      newNode.lng = node.lng;
      newNode.region = '';
      try {
        newNode.region = node.relationships.field_kakadu_region.title;
      } catch (e) {}
      newNode.status = node.field_kar_status; // === 'open';
      newNode.statusDisplay =
        newNode.status === 'open_caution'
          ? 'Open, but beware of risks'
          : newNode.status === 'open'
          ? 'Open'
          : 'Closed';
      let statusColour =
        newNode.status === 'open'
          ? ''
          : newNode.status === 'open_caution'
          ? 'border:1px solid #B45F06; background:#F1C232;'
          : 'border:1px solid #e61f00; background:#fcd8d8;';
      newNode.colour =
        newNode.status === 'open'
          ? '#78be20'
          : newNode.status === 'open_caution'
          ? '#FF6600'
          : '#CB1517';
      newNode.bgColour =
        newNode.status === 'open' ? '' : newNode.status === 'open_caution' ? '#f1c232' : '#fcd8d8';
      newNode.comments = node.comments;
      newNode.additionalComments = node.additionalComments;
      newNode.text =
        node.comments +
        `<div style="font-weight:bold; margin:5px;${statusColour}">` +
        newNode.statusDisplay +
        `</div>`;
      mapPoints.push(newNode);
    }
    // Append a script tag with the KAR locations as an object, then call the
    // function createKARMap() to make the map happen.
    sourceDoc += `<script>
      try{karData = ${JSON.stringify(mapPoints)};
      createKARMap(karData,${latNum},${lonNum},${zoom}); }catch(e){console.log("ERROR",e);}
    </script>
    </body>`;

    // First, output rows for the Main Roads (region === "")
    const tableItemsMainRoad = mapPoints.map(function (node, i, array) {
      if (!node.region) {
        if (i === 0 || (i > 0 && node.region !== array[i - 1].region)) {
          return (
            <>
              <tr>
                <td
                  style={{
                    backgroundColor: '#f9f9f9',
                    color: '#555',
                    fontVariation: 'uppercase',
                    fontWeight: 'bold',
                  }}
                  colSpan="3"
                >
                  Main Roads
                </td>
              </tr>
              <tr>
                <td>{node.title}</td>
                <td style={{ fontWeight: 'bold', color: node.colour }}>{node.statusDisplay}</td>
                <td dangerouslySetInnerHTML={{ __html: node.additionalComments }} />
              </tr>
            </>
          );
        } else {
          return (
            <tr>
              <td aria-label="Title">{node.title}</td>
              <td aria-label="Status" style={{ fontWeight: 'bold', color: node.colour }}>
                {node.statusDisplay}
              </td>
              <td
                aria-label="Comments"
                dangerouslySetInnerHTML={{ __html: node.additionalComments }}
              />
            </tr>
          );
        }
      }
      return;
    });

    // Then output all the others in the queried alphabetical order
    const tableItems = mapPoints.map(function (node, i, array) {
      if (node.region) {
        if (i === 0 || (i > 0 && node.region !== array[i - 1].region)) {
          return (
            <>
              <tr>
                <td
                  style={{
                    backgroundColor: '#f9f9f9',
                    color: '#555',
                    fontVariation: 'uppercase',
                    fontWeight: 'bold',
                  }}
                  colSpan="3"
                >
                  {node.region} Region
                </td>
              </tr>
              <tr>
                <td>{node.title}</td>
                <td style={{ fontWeight: 'bold', color: node.colour }}>{node.statusDisplay}</td>
                <td dangerouslySetInnerHTML={{ __html: node.additionalComments }} />
              </tr>
            </>
          );
        } else {
          return (
            <tr>
              <td aria-label="Title">{node.title}</td>
              <td aria-label="Status" style={{ fontWeight: 'bold', color: node.colour }}>
                {node.statusDisplay}
              </td>
              <td
                aria-label="Comments"
                dangerouslySetInnerHTML={{ __html: node.additionalComments }}
              />
            </tr>
          );
        }
      }
      return '';
    });
    return (
      <div
        key={keyId2}
        style={{
          background: 'white',
          color: 'black',
          display: 'block',
          minHeight: '300px',
          padding: 0,
          border: 0,
          minWidth: '300px',
        }}
      >
        <div
          id="karmap_wrapper"
          className="embed-map-box"
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'hidden',
            minWidth: '300px',
            minHeight: '600px',
            maxHeight: '100vh',
            background: 'white',
            color: 'black',
            padding: 0,
            border: 0,
            margin: 0,
          }}
        >
          {<Iframe content={sourceDoc} key={keyId} title={frameTitle} />}
          <ul>
            Map legend:
            <li>
              <img
                width="30px"
                height="30px"
                src="/icons/map-point-green.svg"
                alt="Green map pin that indicates a park location is open for use"
              />{' '}
              Open
            </li>
            <li>
              <img
                width="30px"
                height="30px"
                src="/icons/map-point-orange.svg"
                alt="Orange map pin that indicates a park location is open conditionally for use"
              />{' '}
              Open, but beware of risks
            </li>
            <li>
              <img
                width="30px"
                height="30px"
                src="/icons/map-point-red.svg"
                alt="Red map pin that indicates a park location is closed for use or not operational"
              />{' '}
              Closed
            </li>
          </ul>
        </div>
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className=" grid-col-12 copy">
              <table id="kar-table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th className="status-table__site-column">Visitor site</th>
                    <th className="status-table__status-column">Status</th>
                    <th className="status-table__comment-column">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {tableItemsMainRoad} {tableItems}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <script src="/js/jscommon.js"></script>
      </div>
    );
  } else {
    // Otherwise, initialise the normal embedded map and shove it in an iframe
    // This object is inside the global 'window' object because I had real hassles getting the
    // Parks information between browsers and when not logged in.
    sourceDoc += `<script>
    // let whichPark = '${whichPark}';
    </script>
    <script src="/js/map-init-embed-map-component.js?centre&lat=${latNum}&long=${lonNum}&zoom=${zoom}&park=${whichPark}&style=${style}"></script></body>`;
    return <>{<Iframe key={keyId} content={sourceDoc} title={frameTitle} />}</>;
  }
}

export default MapEmbed;
