import React from 'react';
import { graphql } from 'gatsby';
import MapEmbed from '../map/MapEmbed';
import { useLocation } from '@reach/router';

export const EmbedMapParagraph = ({ node }) => {
  let embedMap;
  // Default to Satellite style for AMP, regardless of the individual map
  // settings in Drupal
  let style = process.env.GATSBY_PARK === 'amp' ? 'satellite' : node.field_map_style;

  if (node.field_latitude_number != null && node.field_longitude_number != null) {
    // @ts-ignore
    embedMap = (
      <MapEmbed
        title="Map"
        latNum={node.field_latitude_number}
        lonNum={node.field_longitude_number}
        zoom={node.field_zoom}
        points={node.field_map_points}
        style={style}
      />
    );
  }
  const location = useLocation();
  let locClass =
    'mt-xsmall mb-medium ' +
    (location.pathname !== '/access/' ? 'responsive-embed responsive-embed--with-poster-image' : '');
  let keyid = 'map_' + Math.floor(Math.random() * 49152);
  // move 'grid' control into the map/table output
  return (
    <div className="mb-small paragraph_embed_map" key={keyid}>
      <div className={locClass}>{embedMap}</div>
    </div>
  );
};

export const fragment = graphql`
  fragment ParagraphEmbedMap on paragraph__embed_map {
    id
    drupal_id
    internal {
      type
    }
    drupal_internal__id
    field_latitude_number
    field_longitude_number
    field_map_points
    field_map_style
    field_zoom
  }
`;
