import React from 'react';
import { AccordionContainerParagraph } from './accordion-container';
import { ButtonsContainerParagraph } from './buttons-container';
import { DevAids } from '../dev-aids';
import { DownloadsListParagraph } from './downloads-list';
import { EmbedIframeParagraph } from './embed-iframe';
import { EmbedMapParagraph } from './embed-map';
import { EmbedScriptParagraph } from './embed-script';
import { EmbedVideoParagraph } from './embed-video';
import { FeedListParagraph } from './feed-list';
import { FigureParagraph } from './figure';
import { GalleryGridParagraph } from './gallery-grid';
import { HeroImageSectionParagraph } from './hero-image-section';
import { PeopleParagraph } from './people';
import { QuoteWithImageParagraph } from './quote-with-image';
import { SectionHeadingLinksParagraph } from './section-heading-links';
import { TextParagraph } from './text';
import { TileImagesParagraph } from './tile-images';

const components = {
  paragraph__accordion_container: AccordionContainerParagraph,
  paragraph__button_container: ButtonsContainerParagraph,
  paragraph__downloads_list: DownloadsListParagraph,
  paragraph__embed_iframe: EmbedIframeParagraph,
  paragraph__embed_map: EmbedMapParagraph,
  paragraph__embed_script: EmbedScriptParagraph,
  paragraph__embed_video: EmbedVideoParagraph,
  paragraph__feed_list: FeedListParagraph,
  paragraph__figure: FigureParagraph,
  paragraph__hero_image_section: HeroImageSectionParagraph,
  paragraph__image_gallery_grid: GalleryGridParagraph,
  paragraph__people: PeopleParagraph,
  paragraph__quote_with_image: QuoteWithImageParagraph,
  paragraph__section_heading_links: SectionHeadingLinksParagraph,
  paragraph__text: TextParagraph,
  paragraph__tile_images: TileImagesParagraph,
};

//* Pass variables like Gatsby's pageContext data to paragraphs via the callback
//* function of Array.map():
//* paragraphs = someParagraphsArray.map((item) => getParagraph(item, pageContext)),

export const getParagraph = (node, pageContext) => {

  const ParagraphComponent = components[node.type];
  return ParagraphComponent !== undefined ? (
    // Include a key wrapper as these are returned to a .map()
    <React.Fragment key={node.id}>
      {<DevAids node={node} />}
      <ParagraphComponent node={node} pageContextData={pageContext} />
    </React.Fragment>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
};
