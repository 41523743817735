import { useStaticQuery, graphql } from 'gatsby';

export const getKARdata = () => {
  const x = useStaticQuery<GatsbyTypes.kardataQuery>(graphql`
    query kardata {
      allNodeKarLocation(
        sort: { fields: [relationships___field_kakadu_region___title, title], order: [ASC, ASC] }
      ) {
        nodes {
          title
          field_kar_latitude
          field_kar_longitude
          field_kar_status
          field_kar_additional_comments {
            value
          }
          relationships {
            paragraph__information_place {
              field_latitude
              field_longitude
              field_forecast_reopen {
                value
              }
              field_current_open
            }
            field_kakadu_region {
              title
            }
            paragraph__information_walk {
              field_latitude
              field_longitude
            }
            paragraph__information_waterfall {
              field_latitude
              field_longitude
              field_current_open
            }
            field_kar_comments {
              name
            }
          }
        }
      }
    }
  `);
  return x;
};
